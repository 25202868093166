/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

* {
   font-family: "Inter", sans-serif;
   box-sizing: border-box;
   margin: 0;
   padding: 0;
}
.expanded span.brand-logo {
   /* background: red; */
   transition: 0.2s;
   translate: 100%;
   scale: 2;
}
.menu-open span.brand-logo {
   /* background: red; */
   translate: 100%;
   scale: 2;
   transition: 0.2s;
}

.btn-outline-primary {
   color: gray;
   border-color: gray !important;
}
svg.ficon:hover {
   color: rgb(247, 48, 48) !important;
}
.text-primary {
   color: rgba(250, 56, 56, 0.616) !important;
}
.text-primary:hover {
   color: rgb(247, 48, 48) !important;
}
footer {
   display: none;
}

.btn-outline-secondary:not(:disabled):not(.disabled).active,
[dir] .btn-outline-secondary:not(:disabled):not(.disabled):focus {
   background-color: #f2473f !important;
   color: white !important;
}
.form-check-input:checked {
   background-color: #f2473f;
   border-color: #f2473f;
}

.eatslogo {
   width: 56.89px;
   height: 19px;

   font-family: "Inter";
   font-style: normal;
   font-weight: 700;
   font-size: 25.1305px;
   line-height: 30px;

   background: linear-gradient(180.61deg, #5e3225 7.84%, #832a0e 91.39%);
   -webkit-background-clip: text !important;
   -webkit-text-fill-color: transparent !important;
   background-clip: text !important;
   // -webkit-text-stroke-color: transparent;
   // text-fill-color: transparent !important;
}

.empty-order-dish,
.empty-order-ready {
   margin-left: 20px;
   margin-top: 20vh;
}

// timer
.timer {
   display: flex;
   align-items: center;
   strong {
      color: var(--text-gray-900, #1A264E);
font-size: 1rem;
font-family: Inter;
font-weight: 600;
line-height: 1.125rem;
   }
}

.order-wrap {
   width: 100%;
   height: 83vh;
   overflow: hidden;
   display: flex;
   justify-content: space-around;
   flex-direction: row;
   flex-wrap: nowrap;
   transition: all 1s;
   position: relative;
}

.dishes-queue-wrap {
   // border: 2px solid blue;
   display: flex;
   flex-direction: column;
   align-items: center;
   width: 240px;
   min-width: 240px;
   // width: 20%;
   h4 {
      margin-right: auto;
   }
   .spinner {
      position: fixed;
   }
}
.dishes-wrap-head,
.cook-wrap-head,
.ready-wrap-head {
   min-height: 32px;
   display: flex;
   flex-direction: row;
   width: fit-content;
   // margin-right: auto;
   align-items: flex-end;
   align-self: start;
   h4 {
      color: var(--text-gray-800, #28385F);
font-size: 1.125rem;
font-family: Inter;
font-weight: 700;
line-height: 131.187%;
margin: 0;
   }
}
.dishes-queue-body {
   // border: 2px solid sandybrown;
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   flex-wrap: wrap;
   padding: 0 20px 0px 2px;
   overflow: auto;
   height: fit-content;
}
.cook-wrap {
   // border: 2px solid khaki;
   display: flex;
   flex-direction: column;
   // align-items: center;
   width: -webkit-fill-available;
   // padding: 0 10px 0 30px;
   h4 {
      margin-right: auto;
   }
}
.cook-empty-order {
   margin: 20vh auto auto auto;
   // margin-left: 15%;
}
.cook-wrap-body {
   display: grid;
   width: 100%;
   grid-template-columns: repeat(auto-fill, 22rem);
   gap: 0.5rem;
   // grid-template-columns:repeat(auto-fit, minmax(276px, 1fr));
   // grid-auto-rows: minmax(20px, auto);
//   justify-content: center;	
  margin: 0 auto;
   // padding: 0 20px 0;
   overflow: auto;
   // height: 100%;
// column-count: 2;
}
.ready-wrap {
   // border: 2px solid rgba(165, 161, 161, 0.479);
   display: flex;
   flex-direction: column;
   padding-left: 10px;
   align-items: center;
   // margin-left: 50px;
   width: 320px;
   min-width: 190px;
   h4 {
      margin-right: auto;
   }
}
.ready-wrap-body {
   // border: 2px solid rebeccapurple;
   display: flex;
   flex-direction: row;
   justify-content: space-around;
   flex-wrap: wrap;
   width: 100%;
   // padding: 0 20px;
   overflow: auto;
   height: fit-content;
}
//Cooking

.cooking-card {
   /* Auto layout */

   display: flex;
   flex-direction: column;
   justify-content: space-between;
   align-items: flex-start;
   position: relative;
   padding: 22px 16px;
   margin: 5px ;
   gap: 10px;
   width: 97%;
   // grid-template-rows: 1fr auto;
   break-inside: avoid;
   // max-width: 352px;
   // width: 48.5%;
   float: left;
   // height: 381px;
   height: fit-content;
   // min-width: 300px;
   background: #fff8ef;

   /* Text/Gray_200 */
   border: 1px solid #e7ecf0;
   box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
   border-radius: 6px;
   /* Inside auto layout */
   flex: none;
   order: 0;
   flex-grow: 0;
   .cook-header {
      width: 100%;
      height: fit-content;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      h2 {
         color: #000;
font-size: 1.5rem;
font-family: Inter;
font-weight: 700;
line-height: 1.9375rem;
      }
      h3 {
         color: var(--text-gray-900, #1A264E);
font-size: 1.0625rem;
font-family: Inter;
font-weight: 500;
line-height: 1.4375rem;
      }
   }
   .cook-timehead {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .cook-table {
         strong {
            color: var(--text-gray-900, #1A264E);
font-size: 1.0625rem;
font-family: Inter;
font-weight: 500;
line-height: 1.4375rem;
         }
         .table-tex {
            color: var(--text-gray-600, #5B6D8E);
font-size: 0.875rem;
font-family: Inter;
font-weight: 500;
line-height: 1.3125rem;
         }
      }
   }
   .cook-addon-wrap{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
   }
   .cook-addon {
      display: flex;
      // flex-direction: row;
      // justify-content: center;
      // align-items: center;
      // padding: 4px 12px;
      // gap: 6px;
      width: fit-content;
      min-width: 120px;
      height: 29px;
margin: 0 5px ;
      border: 1px solid #ffb956;
      border-radius: 9999px;

      p {
         margin: auto 10px;
         // margin: auto;
         font-family: "Inter";
         font-style: normal;
         font-weight: 500;
         font-size: 12px;
         line-height: 21px;
         color: #1a264e;
      }
   }
   .instruction {
      box-sizing: border-box;
      /* Auto layout */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 4px 4px 8px 8px;
      gap: 8px;
      width: 100%;

      height: 157px;
      min-height: 147px;
      background: #ffffff;
      /* Text/Gray_100 */
      border: 1px solid #edf5f9;
      border-radius: 4px;
      color: #1a264e;
      /* Inside auto layout */
      .inst-head {
         font-family: "Inter";
         font-style: normal;
         font-weight: 600;
         font-size: 12px;
         line-height: 22px;
      }
      .inst-detail {
         // font-family: "Inter";
         // font-style: normal;
         font-weight: 400;
         // font-size: 18px;
         // line-height: 23px;
         color:  #1A264E;
font-size: 1.125rem;
font-family: Inter;
line-height: 1.4375rem;
      }
   }
   .serve-btn {
      height: 2.625rem;
      padding: 0.25rem 0.5rem;

      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;
      color: #1a264e;
      width: 100%;
      height: 42px;

      /* Alert/Warning */

      background: #ffb956;
      border-radius: 112px;

font-size: 1.125rem;
font-family: Inter;
font-weight: 500;
      svg {
         scale: 1.3;
         margin: 10px 5px;

         color: #1a264e;
      }
   }
}

.refresh-toast {
   width: 40vw;
   min-width: 400px;
   border-radius: 10px;
   overflow: hidden;
   .refresh-toast-top {
      background-color: #01a879;
      padding: 8px 16px;
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      .toast-close:hover {
         color: red;
         cursor: pointer;
      }
   }
   .refresh-toast-bottom {
      background-color: #e6f6f2;
      padding: 8px 16px;
      color: #01a879;
   }
}

.dishes-queue {
   display: flex;
   flex-direction: column;
   justify-content: space-around;
   width: 210px;
   min-width: 210px;
   height: 123px;
   padding: 12px 10px;
   border: 1px solid #f0f1f2;
   background: #ffffff;
   border-radius: 4px;
   p {
      margin: 0;
      color: #5b6d8e;
   }
   .dishes-queue-head {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #28385f;
   }
   .dishes-queue-timehead {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-family: "Inter";
      font-style: normal;
   }
   .dishes-queue-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 32px;
      width: 110px;
      font-weight: 600;
      font-size: 14px;
      line-height: 18px;
      height: 32px;
      background: rgba(255, 185, 86, 0.1);
      color: #ffb956;
      border: 0.5px solid #ffb956;
      border-radius: 112px;
   }
}
.dish-btn-spinner {
   min-height: 42px;
   min-width: 42px;
   padding: 3px;
}
.cook-btn-spinner {
   // border: 2px solid blue;
   min-height: 42px;
   min-width: 40px;
   padding: 3px;
}

.dish-spinner {
   width: 40%;
   display: flex;
   color: gray;
   min-width: 40px;
   align-content: center;
}
.serve {
   box-sizing: border-box;
   /* Auto layout */
   display: flex;
   flex-direction: column;
   // align-items: flex-start;
   padding: 12px;
   gap: 10px;
   width: 145px;
   margin: 5px;
   min-height: 75px;
   height: fit-content;
   background: #e3f6ff;
   /* Text/Gray_200 */
   border: 1px solid #e7ecf0;
   border-radius: 4px;
   /* Inside auto layout */
   flex: none;
   order: 0;
   flex-grow: 0;
}
.serve-head {
   display: flex;
   justify-content: space-between;
   // font-family: "Inter";
   // font-style: normal;
   // font-weight: 600;
   // font-size: 16px;
   // line-height: 16px;
   // color: #28385f;

   color: var(--text-gray-800, #28385F);
font-size: 0.875rem;
font-family: Inter;
font-weight: 600;
line-height: 1rem;
}

.table-text {
      // font-family: "Inter";
      // font-style: normal;
      // font-weight: 600;
      // font-size: 14px;
      // line-height: 21px;
      // color: #a1b3c9;
   // text-align: center;

   color: var(--text-gray-400, #A1B3C9);
font-size: 0.875rem;
font-family: Inter;
font-weight: 600;
line-height: 1.3125rem;
   strong {
      // font-family: "Inter";
      // font-style: normal;
      // font-weight: 500;
      // font-size: 17px;
      // line-height: 23px;
      // color: #1a264e;
      color: var(--text-gray-900, #1A264E);
font-size: 1rem;
font-family: Inter;
font-weight: 500;
line-height: 1.4375rem;
   }
}

.serve-take {
   font-family: "Inter";
   width: 146px;
   height: fit-content;
   font-style: normal;
   border-radius: 4px;
   border: 1px solid #f2473f;
   overflow: hidden;
   font-weight: 500;
   font-size: 12px;
   line-height: 21px;
   margin-top: 15px;
   color: #ffffff;
}
.serve-take-head {
   width: 100%;
   height: 22px;
   background: linear-gradient(166.45deg, #f2473f -14.38%, #ff6b34 105%);
}

.serve-take-content {
   display: flex;
   justify-content: space-between;
   font-family: "Inter";
   font-style: normal;
   font-weight: 600;
   font-size: 16px;
   padding: 6px 12px;
   width: 145px;
   min-height: 40px;
   height: fit-content;
   background-color: #fff8f6;
   line-height: 16px;
   color: #28385f;
}

.takeaway {
   font-family: "Inter";
   width: fit-content;
   min-width: fit-content;
   position: relative;
   height: fit-content;
   display: inline-block;
   box-sizing: border-box;
   font-style: normal;
   border-radius: 4px;
   border: 1px solid #f2473f;
   overflow: hidden;
   font-weight: 500;
   font-size: 12px;
   line-height: 21px;
   margin-top: 15px;
   color: #ffffff;
   overflow: hidden;
   .takeaway-head {
      background: linear-gradient(166.45deg, #f2473f -14.38%, #ff6b34 105%);
      height: 22px;

      text-align: center;
   }
   .takeaway-content {
      min-height: fit-content;
      z-index: 1;
   }
}

.dish-margin {
   margin-top: 15px;
}

::-webkit-scrollbar {
   width: 0.5em;
}
::-webkit-scrollbar-track {
   background: rgb(240, 239, 239);
   border-radius: 10px;
}

::-webkit-scrollbar-thumb {
   background: rgba(165, 161, 161, 0.479);
   border-radius: 50px;
}
::-webkit-scrollbar-thumb:hover {
   background: #ff6a348a;
}
.refreshing {
   display: inline;
   // min-height: 32px;
   // min-width: 32px;
}
@keyframes rot {
   from {
      rotate: (0deg);
   }
   to {
      rotate: (360deg);
   }
}
.refreshing:active {
   animation: rot 1s linear infinite forwards;
}
.refreshing:hover {
   cursor: pointer;
}

//   ::-webkit-scrollbar:hover{
// 	height:2em
//   }

@media only screen and (min-width: 850px) and (max-width: 1350px) {
   .dishes-queue-wrap {
      margin: 0 auto;
   }
   .cook-wrap-body{
      column-count: 1;
      
      width: 100%;
   }
   .cooking-card {
      float: none;
      // max-width: 352px;
      // width: 100%;
      margin: 5px auto;
   }
   .ready-wrap {
      margin: 0 auto;
   }
}

@media only screen and (max-width: 851px) {
   .order-wrap {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: -webkit-fill-available;

      overflow: auto;
      padding: 0 10px 0 0;
   }
   .small-spinner {
      margin: 25px auto;
      min-height: 50px;
      width: 40px;
   }
   .dishes-queue-wrap {
      margin-bottom: 10px;
      height: 180px;
      min-height: 190px;
      width: 100%;
      box-sizing: border-box;
   }
   .dishes-queue-body {
      flex-wrap: nowrap;
      width: 100%;
      height: 100%;
      justify-content: flex-start;
      overflow-x: auto;
      overflow-y: hidden;
      padding: 0;
      display: flex;
      flex-direction: row;
   }
   .dish-margin,
   .takeaway {
      margin: 5px;
   }
   .small-take-dishes {
      max-height: 100px;
   }
   .small-content {
      height: 45vh;
      width: 100%;
      overflow: auto;
   }
   .ready-wrap {
      width: 100%;
      margin: 0;
      padding: 0;
   }
   .ready-wrap-body {
      align-items: center;
      margin: 0;
      padding: 0;
      width: 100%;
      overflow: auto;
   }
   .serve {
      width: 100%;
      margin: 10px 0;
   }
   .serve-head {
      width: 100%;
   }
   .serve-take {
      width: 100%;
      margin: 10px 0;
   }
   .serve-take-head {
      width: 100%;
   }
   .serve-take-content {
      width: 100%;
   }
   .table-text {
      width: 100%;
      text-align: start;
   }

   .cook-wrap {
      width: 100%;
   }
   .cook-wrap-body {
      column-count: 1;
      width: 100%;
      padding: 0;
      margin: 0;
      overflow: auto;
      max-height: -webkit-fill-available;
   }
   .cooking-card {
      margin: 10px 0;
      scale: 0.95;
      width: 100%;
   }

   .btn-wrap {
      width: 100%;
      margin: 5px 0;
      display: flex;
      align-items: center;
      justify-content: space-around;
   }
   .btn-grp {
      width: 80%;
      border-radius: 4px;
      overflow: hidden;
      margin: 5px 0px;
      align-items: center;
      display: flex;
      font-weight: 500;
      font-size: 12.6383px;
      line-height: 15px;
      text-align: center;
      letter-spacing: 0.421277px;
      justify-content: space-around;

      button:hover {
         cursor: pointer;
      }
   }
   .btn-active {
      background-color: #702913;
      color: #ffffff;
      border: inherit;
      width: 100%;
      height: 34px;
      margin: auto;
   }
   .mobile-btn {
      background: linear-gradient(
         166.45deg,
         rgba(242, 71, 63, 0.1) -14.38%,
         rgba(255, 107, 52, 0.1) 105%
      );
      border: inherit;
      width: 100%;
      height: 34px;
      margin: auto;
      color: #702913;
   }

   .empty-order-dish {
      margin: auto;
      scale: 0.8;
   }
   .cook-empty-order,
   .empty-order-ready {
      margin: 20px auto;
      scale: 0.8;
   }

   ::-webkit-scrollbar {
      width: 0px;
      height: 0px;
   }
}
.variant{
   color: #292D32;
font-size: 0.75rem;
font-family: Inter;
font-weight: 600;
line-height: 1.3125rem;
}